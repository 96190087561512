<template>
  <div class="app-container">
    <div class="filter-container">
      <el-select v-model="listQuery.coupon_scope" filterable class="filter-item" placeholder="优惠范围" style="width: 140px" clearable>
        <el-option label="全平台" :value="1"></el-option>
        <el-option label="指定店铺" :value="2"></el-option>
      </el-select>
      <el-select v-if="listQuery.coupon_scope == 2" class="filter-item" filterable v-model="listQuery.shop_id" placeholder="请选择店铺" style="width: 140px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="listQuery.coupon_type" class="filter-item" placeholder="优惠类型" style="width: 140px" clearable>
        <el-option label="无门槛" :value="1"></el-option>
        <el-option label="满减券" :value="2"></el-option>
        <el-option label="商品券" :value="3"></el-option>
      </el-select>
      <el-select v-model="listQuery.release" class="filter-item" placeholder="是否发布" style="width: 140px" clearable>
        <el-option label="已发布" :value="1"></el-option>
        <el-option label="未发布" :value="0"></el-option>
      </el-select>
      <el-select v-model="listQuery.expire" class="filter-item" placeholder="是否已过期" style="width: 140px" clearable>
        <el-option label="已过期" :value="1"></el-option>
        <el-option label="未过期" :value="0"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="dialogVisible = true">添加</el-button>

      <el-button class="filter-item" type="success" @click="downloadImportBatchSend">发放模板</el-button>
    </div>
    <div class="filter-container">
      <el-button type="primary" size="small" @click="handleReleaseAll" :disabled="ids.length <= 0">批量发布</el-button>
      <el-button type="primary" size="small" @click="handleDownload">下载二维码</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="selection" align="center" width="60">
      </el-table-column>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_type == 1">无门槛</span>
          <span v-else-if="scope.row.coupon_type == 2">满{{scope.row.minimum}}可用</span>
          <span v-else-if="scope.row.coupon_type == 3">商品优惠</span>
        </template>
      </el-table-column>
      <el-table-column label="使用范围" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_scope == 1">全平台</span>
          <span v-else>{{scope.row.shop.shop_name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="优惠金额" min-width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_type === 3">
            <template v-if="scope.row.discount_mode === 1">
              商品优惠{{scope.row.discount_num}}折
            </template>
            <template v-else-if="scope.row.discount_mode === 2">
              商品立减{{scope.row.discount}}元
            </template>
          </span>
          <span v-else>{{ scope.row.discount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开始时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.start_time }}
        </template>
      </el-table-column>
      <el-table-column label="结束时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.end_time }}
        </template>
      </el-table-column>
      <el-table-column label="总数量" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.total_number }}
        </template>
      </el-table-column>
      <el-table-column label="剩余数量" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.left_number }}
        </template>
      </el-table-column>
      <el-table-column label="发布状态" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.release == 1">已发布</span>
          <span v-else>未发布</span>
        </template>
      </el-table-column>
      <el-table-column label="领取状态" min-width="110" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.is_show == 1">显示</span>
          <span v-else>隐藏</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="230" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.release === 1" size="mini" type="success" @click="handleRelease(scope.row)">发布</el-button>
          <el-button size="mini" type="danger" @click="handleDel(scope.row)">删除</el-button>

          <el-button size="mini" type="primary" @click="handleSend(scope.row)">发放</el-button>

          <el-upload accept=".xlsx,.xls" action="" :show-file-list="false" :auto-upload="false" :on-change="handleImportBatchSend" style="display: inline-block;">
            <el-button type="warning" size="mini" @click="handleBatchSend(scope.row)">批量发放</el-button>
          </el-upload>

          <el-button v-if="scope.row.is_show === 1" type="danger" size="mini" @click="updateIsShow(scope.row)">隐藏</el-button>
          <el-button v-else type="success" size="mini" @click="updateIsShow(scope.row)">显示</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="90px">
        <el-form-item label="使用范围">
          <el-select v-model="form.coupon_scope" filterable clearable>
            <el-option label="全平台" :value="1"></el-option>
            <el-option label="指定店铺" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.coupon_scope == 2" label="指定店铺">
          <el-select size="small" v-model="form.shop_id" placeholder="请选择店铺" filterable clearable>
            <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠类型">
          <el-select v-model="form.coupon_type" clearable>
            <el-option label="无门槛" :value="1"></el-option>
            <el-option label="满减券" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.coupon_type == 2" label="满多少可用">
          <el-input v-model="form.minimum" type="number" min="0" placeholder="请输入满减条件" />
        </el-form-item>
        <el-form-item label="优惠金额">
          <el-input v-model="form.discount" type="number" min="0" placeholder="请输入优惠金额" />
        </el-form-item>
        <el-form-item label="总数量">
          <el-input v-model="form.total_number" type="number" min="0" placeholder="请输入优惠金额" />
        </el-form-item>
        <el-form-item label="过期时间">
          <el-date-picker v-model="form.end_time" type="date" placeholder="请选择过期时间" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="是否发布">
          <el-switch v-model="form.release" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="sendDialogVisible" :close-on-click-modal="false">
      <el-form label-position="left" label-width="90px">
        <el-form-item label="指定用户">
          <el-select size="small" v-model="sendToUserId" placeholder="输入昵称/手机号/姓名进行搜索" filterable remote :remote-method="userFilter"  :loading="userFilterLoading" style="width:340px" clearable>
            <el-option v-for="item in users" :key="item.id" :label="item.nickname" :value="item.id">
              <div style="display: flex;align-items:center;text-align:center;">
                <img :src="item.avatar" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
                <span>{{ item.nickname }} / {{item.phone}} / {{item.name}}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="sendDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveSendCoupon" :loading="sendBtnLoading">确定</el-button>
      </div>
    </el-dialog>

    <!-- 批量发放 -->
    <el-dialog title="导入数据预览" :visible.sync="importBatchSendVisible" width="60%" :close-on-click-modal="false">
      <div style="height: 500px" class="scrollbar">
        <el-scrollbar style="height: 500px">
          <el-table :data="importBatchSendData" style="width: 1100px">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.index || "" }}
              </template>
            </el-table-column>

            <el-table-column label="电话" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.phone || "" }}
              </template>
            </el-table-column>

            <el-table-column label="数量" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.num || "" }}
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="importBatchSendVisible = false">取消</el-button>
        <el-button type="primary" @click="importBatchSendSaveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 批量发放 -->
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import downloadExcel from "vue-json-excel";
import {excel2Json} from "@/utils/excel";

export default {
  components: { downloadExcel },
  data() {
    return {
      shops: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: ""
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        shop_id: "",
        coupon_type: 1,
        coupon_scope: 1,
        minimum: 0,
        discount: 0,
        start_time: "",
        end_time: "",
        total_number: 0,
        release: 0,
        expire: "",
      },
      ids: [],
      sendDialogVisible: false,
      users: [],
      userFilterLoading: false,
      sendCouponId: "",
      sendToUserId: "",
      sendBtnLoading: false,
      // 批量发放
      batchSendCouponId: "",
      importBatchSendData: [],
      importBatchSendVisible: false,
    };
  },
  created() {
    this.getList();
    this.getShopList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getShopList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_id: "",
          shop_id: "",
          coupon_type: 1,
          coupon_scope: 1,
          minimum: 0,
          discount: 0,
          start_time: "",
          end_time: "",
          total_number: 0,
          release: 0
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/coupon/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id
        }
      }).then(response => {
        this.shops = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleRelease(item) {
      this.$confirm("确定要发布该优惠券吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/coupon/release",
          method: "post",
          params: {
            id: item.id
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleReleaseAll() {
      if (this.ids.length <= 0) {
        this.$message({
          type: "warning",
          message: "请选择项!"
        });
        return;
      }
      this.$confirm("确定要发布选中项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/coupon/release",
          method: "post",
          data: {
            ids: this.ids
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleSend(item) {
      this.sendCouponId = item.id;
      this.sendDialogVisible = true;
    },
    userFilter(val) {
      this.userFilterLoading = true
      request({
          url: "/api/backend/user/list",
          method: "get",
          params: {
            page: 1,
            limit: 10,
            school_id: this.school_id,
            keyword: val,
          }
        }).then(response => {
          this.users = response.data.data;
        }).finally(()=>{
          this.userFilterLoading = false
        })
    },
    handleDel(item) {
      this.$confirm("确定要删除该优惠券吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/coupon/del",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach(element => {
        ids.push(element.id);
      });
      this.ids = ids;
    },
    saveData() {
      this.form.school_id = this.school_id;
      if (!this.form.school_id) {
        this.$message({
          type: "warning",
          message: "请先选择学校"
        });
        return;
      }
      if (this.form.coupon_scope == 2 && !this.form.shop_id) {
        this.$message({
          type: "warning",
          message: "请选择指定店铺"
        });
        return;
      }
      if (this.form.coupon_type == 2 && this.form.minimum <= 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的优惠条件"
        });
        return;
      }
      if (this.form.discount <= 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的优惠金额"
        });
        return;
      }
      if (!this.form.end_time) {
        this.$message({
          type: "warning",
          message: "请选择过期时间"
        });
        return;
      }
      if (!this.form.total_number) {
        this.$message({
          type: "warning",
          message: "请输入总数量"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/coupon/save",
        method: "post",
        data: this.form
      })
        .then(() => {
          this.dialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    saveSendCoupon() {
      this.sendBtnLoading = true;
      request({
        url: "/api/backend/coupon/send",
        method: "post",
        data: {
          coupon_id: this.sendCouponId,
          user_id: this.sendToUserId,
        }
      })
        .then(() => {
          this.sendDialogVisible = false
          this.getList()
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.sendBtnLoading = false;
        });
    },
    handleDownload() {
      request({
        url: "/api/common/qrcode/wxMiniCode",
        method: "post",
        data: {
          url: `pages/coupon?school_id=${this.school_id}`,
        }
      })
        .then((response) => {
          let a = document.createElement("a");
          a.href = response.data
          a.download = '领券中心.png'
          a.click()
        });
    },
    // 发放模板
    downloadImportBatchSend() {
      let a = document.createElement("a");
      a.href = "/发放模板.xlsx";
      a.download = "发放模板.xlsx";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    // 批量发放
    handleBatchSend(row) {
      this.batchSendCouponId = row.id;
    },
    handleImportBatchSend(file) {
      this.importBatchSendData = [];
      file = file.raw;
      if (!(file.name.split(".")[1] === "xlsx" || file.name.split(".")[1] === "xls")) {
        this.$message.error("上传文件格式不正确，请检查选择的文件");
        return;
      }
      excel2Json(file, 1).then((excelData) => {
        // 处理业务
        if (excelData.length <= 0) {
          this.$message.error("数据不能为空");
          return;
        }
        if (excelData.length >= 100) {
          this.$message.error(`数据批量处理限制100以内,当前数量${excelData.length}`);
          return;
        }
        excelData.forEach((element, index) => {
          this.importBatchSendData.push({
            index: element["序号"]||"",
            phone: element["电话"].toString()||"",
            num: element["数量"]||"",
          })
        });
        this.importBatchSendVisible = true;
      });
    },
    importBatchSendSaveData() {
      this.btnLoading = true;
      for (let index = 0; index < this.importBatchSendData.length; index++) {
        const item = this.importBatchSendData[index];
        for (var key in item) {
          if (!item[key]) {
            this.$message({
              type: "error",
              message: "请先完善数据",
            });
            this.btnLoading = false;
            return;
          }
        }
      }
      request({
        url: "/api/backend/coupon/batchSend",
        method: "post",
        data: {
          coupon_id: this.batchSendCouponId,
          data: this.importBatchSendData,
        },
        timeout: 10 * 60 * 1000,
      }).then((response) => {
        this.btnLoading = false;
        this.importBatchSendVisible = false;
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.getList();
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.btnLoading = false;
      });
    },
    // 更新是否显示
    updateIsShow(row){
      this.listLoading = true
      request({
        url: '/api/backend/coupon/updateIsShow',
        method: 'post',
        data: {id: row.id},
      }).then(() => {
        this.getList()
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.listLoading = false
      })
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
